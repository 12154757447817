#reporteheader .row > div {
    /* min-width: 10.5rem !important; */
    height: 5rem;
}

.interlined{
    border-bottom: solid 3px #3B3B41;
}

img{
    width: 100%;
}