.react-bs-container-header{
    background-color: #282828;
    border-radius: 4px 4px 0px 0px;
    padding-bottom: 0 !important;
}

.table {
    min-width: 60rem;
}

.table th{
    border: 0;
    color: white;
}

.page-link{    
    background-color: transparent;    
    overflow: hidden;
    color: #282828;    
}

.page-link:hover{ 
    color: #282828;
    border-radius: 50%;
}

.page-item:first-child {
    border-radius: 50%;
    background-color: #FF3100;
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    background-image: url("../assets/img/icons/29-Izquierdad-1.svg") !important; 
    background-repeat: no-repeat; 
    background-position: center;
    background-size: 55%;
    margin-right: 0.7rem;
}

.page-item:last-child {
    border-radius: 50%;   
    background-color: #FF3100; 
    width: 2rem;
    height: 2rem; 
    overflow: hidden;
    margin-left: 0.7rem;
    background-image: url("../assets/img/icons/28-DerechaB.svg") !important; 
    background-repeat: no-repeat; 
    background-position: center; 
    background-size: 55%;
    margin-left: 0.7rem;
}
.page-item:last-child .page-link{ 
    width: 2rem;
    height: 2rem;  
    opacity: 0;
    
}
.page-item:first-child .page-link{
    width: 2rem;
    height: 2rem;  
    opacity: 0;
    
}

.page-item.active .page-link {
    color: #FF3100;
    padding-right: 13px;
    padding-left: 13px;
    border-radius: 50%;
    background-color: #DBDBDB;;
    border-color: #DBDBDB;    
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgb(255 49 0 /25% ) !important; 
    box-shadow: 0 0 0 0.2rem rgb(255 49 0 /25% ) !important;
}

.page-item {
    border-radius: 50%;
    box-shadow: none !important;
    margin-left: 2px;
    margin-left: 2px;
}

.disabled .page-item{
    background-color: #FF3100 !important;
}

.table-hover tbody tr:hover{  
    outline: 2px solid #282828;   
    outline-offset: -2px;
    background-image: url("../assets/img/icons/barra-lateral.svg") !important; 
    background-repeat: no-repeat;  
    background-size: calc(0.em + 0.5rem) calc(1.5em + 2.375rem);
   
}
