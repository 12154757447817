.uploader {
    align-items: center;
    background-color: white;
    cursor: pointer;
    display: flex;
    height: 150px;
    width: 150px;
    justify-content: center;
    /* outline: 2px solid #555555; */
    /* margin-top: 7px; */
    position: relative;
    border-radius: 10px;
    box-shadow: 0 0 0 2px rgba(100, 100, 100, 0.3);
}
.uploader input {
    display: none;
}
.uploader img, .uploader .icon {
    pointer-events: none;
}
.uploader, .uploader .icon {
    transition: all 100ms ease-in;
}
.uploader .icon {
    /* color: #eee; */
    /* color: rgba(0, 0, 0, 0.2); */
    /* font-size: 5em; */
    width: 90px;
    /* z-index: 1; */
    top: 44% !important;
}
.uploader img {
    left: 50%;
    opacity: 0;
    max-height: 100%;
    max-width: 100%;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    transition: all 300ms ease-in;
    transform: translate(-50%, -50%);
    /* z-index: -1; */
}
.uploader img.loaded {
    opacity: 1 !important;
    /* z-index: 1; */
}
.texto {
    margin-top: 175px;
}

.uploaderDocs{
    align-items: center;
    background-color: #8E8E8E;
    cursor: pointer;
    display: flex;
    height: 190px;
    justify-content: center;
    position: relative;
    width: 190px;
}
.uploaderDocs input {
    display: none;
}
.uploaderDocs img, .uploaderDocs .icon {
    pointer-events: none;
}
.uploaderDocs, .uploaderDocs .icon {
    transition: all 100ms ease-in;
}
.uploaderDocs .icon {
    color: #eee;
    color: rgba(0, 0, 0, 0.2);
    font-size: 5em;
    width: 150px;
    /* z-index: 1; */
    top: 40% !important;
}
.uploaderDocs img {
    left: 50%;
    opacity: 0;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transition: all 300ms ease-in;
    transform: translate(-50%, -50%);
    z-index: -1;
}
.uploaderDocs img.loaded {
    opacity: 1 !important;
    z-index: 1;
}

.texto-blanco {
    margin-top: 150px;
    color: white;
}

.docs{
    width: 190px;
    height: 190px;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 1px solid #8E8E8E;
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
    background-color: transparent;
}

.img-container {
    /*position: relative;*/
    /*width: 50%;*/
}
.img-container:hover .docs {
    opacity: 0.3;
}
.img-container:hover .middle {
    opacity: 1;
}

.icon-delete {
    color: red;
    font-size: 16px;
    cursor: pointer;
    background: transparent;
    border: none !important;
}
.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 8%;
    left: 75%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
.docs img{
    width: 90%;
}

.docs img.zoom:hover{
    cursor: zoom-in;
}
.loaded-excel{
    padding: 25px;
}