#custom-file-uploader.uploader {
    align-items: center;
    cursor: pointer;
    display: flex;
    width: 6.5rem;
    height: 6.5rem;
    justify-content: center;
    outline: none;
    position: relative;
    border-radius: 50%; 
    /* transform: translateY(65%); */
    background-color: transparent;
    box-shadow: none;
}
#custom-file-uploader.uploader input {
    display: none;
}
#custom-file-uploader.uploader img, .uploader .icon {
    pointer-events: none;
}
#custom-file-uploader.uploader, .uploader .icon {
    transition: all 100ms ease-in;
}
#custom-file-uploader.uploader .icon {
    color: #eee;
    color: rgba(0, 0, 0, 0.2);
    font-size: 5em;
    width: 150px;
    z-index: 1;
    top: 40% !important;
}
#custom-file-uploader.uploader img {
    left: 50%;
    opacity: 0;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transition: all 300ms ease-in;
    transform: translate(-50%, -50%);
    z-index: -1;
}
#custom-file-uploader.uploader img.loaded {
    opacity: 1 !important;
    z-index: 1;
    border-radius: 50%;
    width: 6.5rem;
    height: 6.5rem;
    border: 5px solid #FFFFFF;
}
#custom-file-uploader .camera-container{
    z-index: 2;
    padding: 15%;
    transform: translate(100%, 100%);
}
#custom-file-uploader .cameraIcon{
    border-radius: 50%;
    background-color: #3b3b41c7;
}
#custom-file-uploader.texto {
    margin-top: 175px;
}

#custom-file-uploader.uploaderDocs{
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 190px;
    justify-content: center;
    position: relative;
    width: 190px;
}
#custom-file-uploader.uploaderDocs input {
    display: none;
}
#custom-file-uploader.uploaderDocs img, .uploaderDocs .icon {
    pointer-events: none;
}
#custom-file-uploader.uploaderDocs, .uploaderDocs .icon {
    transition: all 100ms ease-in;
}
#custom-file-uploader.uploaderDocs .icon {
    color: #eee;
    color: rgba(0, 0, 0, 0.2);
    font-size: 5em;
    width: 150px;
    z-index: 1;
    top: 40% !important;
}
#custom-file-uploader.uploaderDocs img {
    left: 50%;
    opacity: 0;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transition: all 300ms ease-in;
    transform: translate(-50%, -50%);
    z-index: -1;
}
#custom-file-uploader.uploaderDocs img.loaded {
    opacity: 1 !important;
    z-index: 1;
}

#custom-file-uploader.texto-blanco {
    margin-top: 150px;
    color: white;
}

#custom-file-uploader.docs{
    width: 190px;
    height: 190px;
    margin-right: 20px;
    margin-bottom: 20px;
    /* border: 1px solid #8E8E8E; */
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
    background-color: transparent;
}
#custom-file-uploader.img-container:hover .docs {
    opacity: 0.3;
}
#custom-file-uploader.img-container:hover .middle {
    opacity: 1;
}

#custom-file-uploader.icon-delete {
    color: red;
    font-size: 16px;
    cursor: pointer;
    background: transparent;
    border: none !important;
}
#custom-file-uploader.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 8%;
    left: 75%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
#custom-file-uploader.docs img{
    width: 90%;
}

#custom-file-uploader.docs img.zoom:hover{
    cursor: zoom-in;
}
