@media (min-width: 768px) {
    .profile-header {
        transform: translateY(-20%);
        background-color: #ff9c84;
        padding: 1rem;
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    .profile-foto {
        margin-top: 2rem;
        transform: translateY(65%);
    }
    .h-info {
        justify-content: space-between;
    }
    .h-foto {
        flex-direction: row;
        align-items: flex-end;
    }
    .form-profile {
        margin-top: 10rem;
    }
}

@media (max-width: 768px) {
    .profile-header {
        transform: translateY(-20%);
        background-color: #ff9c84;
        padding: 1rem;
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    .profile-foto {
        margin-top: 7rem;
    }
    .h-info {
        flex-direction: column;
    }
    .h-foto {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    #editar-btn{
        margin-top: 0.8rem;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .form-profile {
        margin-top: 15rem;
    }
}

.profile-container {
    margin: 0 10%;
}
.name-dark {
    color: #3b3b41;
}
.profile-header > div {
    margin: 0 15%;
}
/* .profile-header > div > div:first-child{
    margin-left: 20%;
} */
.form-profile > div {
    padding-right: 10%;
    padding-left: 10%;
}

#headingOne {
    background-color: #dbdbdb;
    border-radius: 22px;
}

#passForm,
#passFormInputs {
    -webkit-transition: 0.35s ease-in-out;
    transition: 0.35s ease-in-out;
}
