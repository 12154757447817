.btn-outline-black  #arrow_down{
    display: flex;
    text-indent: -9999px;
    width: 15px;
    height: 15px;
    background: url(../../../../../assets/img/icons/AbajoGris.svg);
    background-size: 15px 15px;
    padding-left: 1rem;
}

.btn-outline-black:hover  #arrow_down{
    display: flex;
    text-indent: -9999px;
    width: 15px;
    height: 15px;
    background: url(../../../../../assets/img/icons/Abajo.svg);
    background-size: 15px 15px;
    padding-left: 1rem;
}

.d-flex #icono_actividades{
    display: flex;
    text-indent: -9999px;
    width: 60px;
    height: 60px;
    background: url(../../../../../assets/img/icons/46-Actividad.svg);
    background-size: 60px 60px;
    padding-left: 1rem;
}
.d-flex #icono_asistencia{
    display: flex;
    text-indent: -9999px;
    width: 60px;
    height: 60px;
    background: url(../../../../../assets/img/icons/Asistencia.svg);
    background-size: 60px 60px;
    padding-left: 1rem;
}

.insidetable thead tr:nth-of-type(odd){
    background-color: #282828 !important;
}
/* -------------------- Estilos para las barras de grafica de etnia ----------- */
/* .margenes-grafica{
    margin-left: 2px;
    margin-right: 2px;
}
.barra-verde-oscuro{
    background-color: "#217D66";
    height: 150px;
    width: 25px;
    border-radius: 50px;
    position: relative;
    z-index: 3;
    box-shadow: 2px;
    display: flex;
}
.barra-verde-oscuro{
    background-color: "#29C9A1";
    position: absolute;
    width: 25px;
    border-radius: 50px;
    align-items: initial;
    justify-content: center;
    bottom: 0;
    z-index: 2;
    text-align: center;
} */
.texto-rotado{
    -webkit-transform: rotate(80deg); 
    -moz-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    height:20px;
    width: 125px;
    margin-bottom: 50px;
    margin-top: 0px;
    padding-bottom: 40px;
    left:1;
}

.texto-rotado-2{
    -webkit-transform: rotate(80deg); 
    -moz-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    height:50px;
    width: 35px;
    margin-bottom: 20px;
    margin-top: 0px;
    padding-bottom: 40px;
    left:1;
}
.texto-rotado-barra{
    -webkit-transform: rotate(-90deg); 
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    height:60px;
    width: 65px;
    margin-bottom: 2px;
    margin-top: 0px;
    right:1;
}

.position-drop{
    position: absolute;
    z-index: 9999;
}

  
  /* .seperator {
    width: 100%;
  } */
  
 /*  .controls-wrapper {
    width: 100%;
  } */
  
.carousel-wrapper {
    width: 100%;
  }
  
.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 0px violet;
}
 
.rec-pagination{
    display: none !important;
    visibility: hidden !important;
  } 


.rec-item-wrapper{
    width: auto !important;
}
.shadow-tooltip{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    
}
 
