@font-face {
    font-family: 'MontserratAlternates';
    src: url('../assets/fonts/MontserratAlternates-Bold.ttf') format("truetype"),
        url('../assets/fonts/MontserratAlternates-SemiBold.ttf') format("truetype"),
        url('../assets/fonts/MontserratAlternates-Regular.ttf') format("truetype");
  }



.btn{
    border-radius: 1.25rem;
    font-weight: 700 !important;
    font-family: 'MontserratAlternates';
    border: 1px solid transparent;
    padding: .5625rem 1rem;
    font-size: .75rem;
    line-height: 1.125;
}

/*-------------------------------------------- Boton  prmario ---------------------------------------------*/
.btn-primary {
    color: #fff;
    border-color: #FF3100;
    background-color: #FF3100;
    box-shadow: none
}

.btn-primary:hover {
    color: #fff;
    background-color: #C92E09;
    border-color: #C92E09
}

.btn-primary:focus {
    color: #fff;
    background-color: #C92E09;
    border-color: #C92E09
}

.btn-primary.focus, .btn-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 49, 0, .5)
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #C92E09;
    border-color: #C92E09
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #C92E09;
    border-color: #C92E09;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) !important
} 


/*-------------------------------------------- Boton secundario ---------------------------------------------*/

.btn-secondary {
    color: #fff;
    border-color: #EF8B1D;
    background-color: #EF8B1D;
    box-shadow: none
}

.btn-secondary:hover {
    color: #fff;
    background-color: #EF8B1D;
    border-color: #EF8B1D
}

.btn-secondary.focus, .btn-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 49, 0, .5)
}

.btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #EF8B1D;
    border-color: #EF8B1D
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #EF8B1D;
    border-color: #EF8B1D;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) !important
} 

/*-------------------------------------------- Boton light ---------------------------------------------*/

.btn-light {
    color: #3B3B41;
    font-weight: bold;
    border-color: #DBDBDB;
    background-color: #DBDBDB;
    box-shadow: none
}

.btn-light:hover {
    color: #3B3B41;
    font-weight: bold;
    background-color: #ABABAB;
    border-color: #ABABAB
}

.btn-light.focus, .btn-light:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 0, 0, .125)
}

.btn-light.disabled, .btn-light:disabled {
    color: #3B3B41;
    font-weight: bold;
    background-color: #EF8B1D;
    border-color: #EF8B1D
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
    color: #3B3B41;
    font-weight: bold;
    background-color: #DBDBDB;
    border-color: #DBDBDB;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) !important
} 

/*-------------------------------------------- Boton outline primary ---------------------------------------------*/

.btn-outline-primary {
    background-color: initial;
    background-image: none;
    border-color: #FF3100;
    color: #FF3100
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #FF3100;
    border-color: #FF3100;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .05), 0 4px 10px rgba(23, 198, 113, .25)
}

.btn-outline-primary.focus, .btn-outline-primary:focus {
    box-shadow: 0 0 0 3px rgba(23, 198, 113, .15), 0 3px 15px rgba(23, 198, 113, .2), 0 2px 5px rgba(0, 0, 0, .1) !important
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #FF3100;
    background-color: initial;
    box-shadow: none
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #FF3100;
    border-color: #FF3100
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) !important
}


/*-------------------------------------------- Boton outline secundario ---------------------------------------------*/

.btn-outline-secondary {
    background-color: initial;
    background-image: none;
    border-color: #EF8B1D;
    color: #EF8B1D
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #EF8B1D;
    border-color: #EF8B1D;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .05), 0 4px 10px rgba(23, 198, 113, .25)
}

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 3px rgba(23, 198, 113, .15), 0 3px 15px rgba(23, 198, 113, .2), 0 2px 5px rgba(0, 0, 0, .1) !important
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #EF8B1D;
    background-color: initial;
    box-shadow: none
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #EF8B1D;
    border-color: #EF8B1D
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) !important
}


/*-------------------------------------------- Boton outline success ---------------------------------------------*/

.btn-outline-success {
    background-color: initial;
    background-image: none;
    border-color: #2AD194;
    color: #2AD194
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #2AD194;
    border-color: #2AD194;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .05), 0 4px 10px rgba(23, 198, 113, .25)
}

.btn-outline-success.focus, .btn-outline-success:focus {
    box-shadow: 0 0 0 3px rgba(23, 198, 113, .15), 0 3px 15px rgba(23, 198, 113, .2), 0 2px 5px rgba(0, 0, 0, .1) !important
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #2AD194;
    background-color: initial;
    box-shadow: none
}

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #2AD194;
    border-color: #2AD194
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) !important
}


/*-------------------------------------------- Boton outline info ---------------------------------------------*/

.btn-outline-info {
    background-color: initial;
    background-image: none;
    border-color: #00b8d8;
    color: #00b8d8
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #00b8d8;
    border-color: #00b8d8;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .05), 0 4px 10px rgba(0, 184, 216, .25)
}

.btn-outline-info.focus, .btn-outline-info:focus {
    box-shadow: 0 0 0 3px rgba(0, 184, 216, .15), 0 3px 15px rgba(0, 184, 216, .2), 0 2px 5px rgba(0, 0, 0, .1) !important
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00b8d8;
    background-color: initial;
    box-shadow: none
}

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00b8d8;
    border-color: #00b8d8
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) !important
}

/*-------------------------------------------- Boton outline advertencia ---------------------------------------------*/

.btn-outline-warning {
    background-color: initial;
    background-image: none;
    border-color: #ffb400;
    color: #ffb400
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #ffb400;
    border-color: #ffb400;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .05), 0 4px 10px rgba(255, 180, 0, .25)
}

.btn-outline-warning.focus, .btn-outline-warning:focus {
    box-shadow: 0 0 0 3px rgba(255, 180, 0, .15), 0 3px 15px rgba(255, 180, 0, .2), 0 2px 5px rgba(0, 0, 0, .1) !important
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffb400;
    background-color: initial;
    box-shadow: none
}

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffb400;
    border-color: #ffb400
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) !important
}

/*-------------------------------------------- Boton outline peligro ---------------------------------------------*/

.btn-outline-danger {
    background-color: initial;
    background-image: none;
    border-color: #c4183c;
    color: #c4183c
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #c4183c;
    border-color: #c4183c;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .05), 0 4px 10px rgba(196, 24, 60, .25)
}

.btn-outline-danger.focus, .btn-outline-danger:focus {
    box-shadow: 0 0 0 3px rgba(196, 24, 60, .15), 0 3px 15px rgba(196, 24, 60, .2), 0 2px 5px rgba(0, 0, 0, .1) !important
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #c4183c;
    background-color: initial;
    box-shadow: none
}

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #c4183c;
    border-color: #c4183c
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) !important
}

/*-------------------------------------------- Boton outline light ---------------------------------------------*/

.btn-outline-light {
    background-color: initial;
    background-image: none;
    border-color: #fbfbfb;
    color: #212529
}

.btn-outline-light:hover {
    color: #212529;
    background-color: #fbfbfb;
    border-color: #fbfbfb;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .05), 0 4px 10px hsla(0, 0%, 98.4%, .25)
}

.btn-outline-light.focus, .btn-outline-light:focus {
    box-shadow: 0 0 0 3px hsla(0, 0%, 98.4%, .15), 0 3px 15px hsla(0, 0%, 98.4%, .2), 0 2px 5px rgba(0, 0, 0, .1) !important
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #fbfbfb;
    background-color: initial;
    box-shadow: none
}

.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #fbfbfb;
    border-color: #fbfbfb
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) !important
}

.btn-outline-dark {
    background-color: initial;
    background-image: none;
    border-color: #212529;
    color: #212529
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .05), 0 4px 10px rgba(33, 37, 41, .25)
}

.btn-outline-dark.focus, .btn-outline-dark:focus {
    box-shadow: 0 0 0 3px rgba(33, 37, 41, .15), 0 3px 15px rgba(33, 37, 41, .2), 0 2px 5px rgba(0, 0, 0, .1) !important
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #212529;
    background-color: initial;
    box-shadow: none
}

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) !important
}

/*-------------------------------------------- Boton outline trasparente ---------------------------------------------*/

.btn-outline-white {
    background-color: initial;
    background-image: none;
    border-color: #fff;
    color: #212529;
    color: #fff
}

.btn-outline-white:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .05), 0 4px 10px hsla(0, 0%, 100%, .25)
}

.btn-outline-white.focus, .btn-outline-white:focus {
    box-shadow: 0 0 0 3px hsla(0, 0%, 100%, .15), 0 3px 15px hsla(0, 0%, 100%, .2), 0 2px 5px rgba(0, 0, 0, .1) !important
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #fff;
    background-color: initial;
    box-shadow: none
}

.btn-outline-white:not(:disabled):not(.disabled).active, .btn-outline-white:not(:disabled):not(.disabled):active, .show > .btn-outline-white.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff
}

.btn-outline-white:not(:disabled):not(.disabled).active:focus, .btn-outline-white:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-white.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) !important
}

.btn-outline-white:not(:disabled):not(.disabled).active, .btn-outline-white:not(:disabled):not(.disabled):active {
    color: #000
}

/*-------------------------------------------- Boton outline negro ---------------------------------------------*/

.btn-outline-black {
    background-color: initial;
    background-image: none;
    border-color: #3B3B41;
    color:#3B3B41
}

.btn-outline-black:hover {
    color: #fff;
    background-color: #3B3B41;
    border-color: #3B3B41;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .05), 0 4px 10px rgba(0, 0, 0, .25)
}

.btn-outline-black.focus, .btn-outline-black:focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, .15), 0 3px 15px rgba(0, 0, 0, .2), 0 2px 5px rgba(0, 0, 0, .1) !important
}

.btn-outline-black.disabled, .btn-outline-black:disabled {
    color: #3B3B41;
    background-color: initial;
    box-shadow: none
}

.btn-outline-black:not(:disabled):not(.disabled).active, .btn-outline-black:not(:disabled):not(.disabled):active, .show > .btn-outline-black.dropdown-toggle {
    color: #fff;
    background-color: #3B3B41;
    border-color: #3B3B41
}

.btn-outline-black:not(:disabled):not(.disabled).active:focus, .btn-outline-black:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-black.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) !important
}

.btn-outline-black:not(:disabled):not(.disabled).active, .btn-outline-black:not(:disabled):not(.disabled):active {
    color: #fff
}