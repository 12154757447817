.notification-container {
  box-sizing: border-box ;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px 15px !important;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
  
} 

.notification {
  
  box-sizing: border-box;
  padding: 15px 25px 25px 75px  !important;
  border-radius: 0.5rem  !important; 
  color: #000000 !important;
  background-color: white !important;
  box-shadow: 0 0 12px #999 !important; 
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 1 !important;
  margin-top: 15px;
}

.notification .title {
  font-size: 1em !important;
  line-height: 1.2em !important;
  font-weight: bold;
  margin: 0 0 5px 0 !important;
}

.notification:hover, .notification:focus {
  opacity: 0.9 !important;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  font-family: 'Notification';
  width: 28px;
  height: 28px;
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}

.notification-info {
  border: #008FF1 1px solid !important;
  background-color: white !important;
}

.notification-info:before {
  font-size: 60px !important; 
  /* color: #008FF1; */
  -webkit-text-stroke: 2px #008FF1 !important;
  color: transparent !important; 
  content: "" !important;
}

.notification-success {
  border: #2AD194 1px solid !important;
  background-color: white !important;
}

.notification-success:before {
  font-size: 60px !important;
 /*  color: #2AD194; */
  -webkit-text-stroke: 2px #2AD194 !important;
  color: transparent !important; 
  content: "" !important;

}

.notification-warning {
  border: 1px solid #f89406 !important;
  background-color: white !important;
}

.notification-warning:before { 
  font-size: 60px !important; 
  /* color: #f89406; */
  -webkit-text-stroke: 2px #f89406 !important;
  color: transparent !important; 
  content: "" !important;
}

.notification-error {
  border: #FF3100 1px solid !important;
  background-color: white !important;
  
}

.notification-error:before {
  font-size: 60px !important; 
  /* color: #FF3100; */
  -webkit-text-stroke: 2px #FF3100 !important;
  color: transparent !important; 
  content: "" !important;
}
