@font-face {
    font-family: 'MontserratAlternates-SemiBold';
    src: url('../assets/fonts/MontserratAlternates-SemiBold.ttf') format("embedded-opentype");
  }
  

h1, h2, h3, h4, h5, h6 {
    font-family: 'MontserratAlternates-Regular';
    font-weight: bold;
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: bold;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block;
  font-weight: bold;
}