@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.center {
    text-align: center;
}
.titulo-modal h4 {
    color: #ff3100;
}

.special_modal .modal-content {
    background-color: transparent;
}


