.nav-link #IconInicio {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/Inicio.svg);
    background-size: 20px 20px;
}

.nav-link.active #IconInicio, .nav-link:hover #IconInicio {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/InicioB.svg);
    background-size: 20px 20px;
}

/* beneficiario */
.nav-link #IconBeneficiario {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/Beneficiario.svg);
    background-size: 20px 20px;
}

.nav-link.active #IconBeneficiario, .nav-link:hover #IconBeneficiario {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/BeneficiarioB.svg);
    background-size: 20px 20px;
}

/* Admin */
.nav-link #IconAdmin {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/Settings2.svg);
    background-size: 20px 20px;
}

.nav-link.active #IconAdmin, .nav-link:hover #IconAdmin, .nav-link[aria-expanded="true"] #IconAdmin {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/Settings.svg);
    background-size: 20px 20px;
}
/* bitacoras */
.nav-link #IconBitacoras {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/Reporte.svg);
    background-size: 20px 20px;
}

.nav-link.active #IconBitacoras, .nav-link:hover #IconBitacoras, .nav-link[aria-expanded="true"] #IconBitacoras {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/ReporteB.svg);
    background-size: 20px 20px;
}
/* logout */
.nav-link #IconLogOut {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/SalirB.svg);
    background-size: 20px 20px;
}

.nav-link.active #IconLogOut, .nav-link:hover #IconLogOut {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/Salir.svg);
    background-size: 20px 20px;
}

