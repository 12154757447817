.vertical-divider {
    border-left: 3px solid #DBDBDB;
}

.icon-borde {
    -webkit-text-stroke: 2px #3b3b41;
    color: transparent;
}

.icon-borde:hover {
    -webkit-text-stroke: 1px #ffffff;
    border-radius: 50%;
    background-color: #2ad194;
    color: #2ad194;
    /* box-shadow: 0 0 5px #2AD194; */
}
.icon-borde-see:hover {
    -webkit-text-stroke: 1px #ffffff;
    border-radius: 50%;
    background-color: #3B3B41;
    color: #3B3B41;
    /* box-shadow: 0 0 5px #2AD194; */
}

.icon-borde-remove {
    color: #3b3b41;
}



.icon-borde-remove:hover {
    border-radius: 50%;
    color: #ffffff;
    background-color: #ff3100;
}
.icon-borde-remove:hover i {
    color:  #ffffff;
}
/*---------------------- Modificacion checkbox --------------------------*/
.checkbox span {
    border-radius: 35% !important;
}

input:checked label {
    color: #ff3100;
}

/*---------------------- Modificacion Datepicker --------------------------*/
.react-date-picker__wrapper {
    width: 100%;
    padding-top: 0.275rem;
    padding-bottom: 0.275rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: white;
    border: solid 1px gray;
    border-width: 1px 0;
    display: flex;
    border: 1px solid #e1e5eb;
    border-radius: 1.25rem;
}

.react-date-picker__button:enabled:hover svg g,
.react-date-picker__button:enabled:focus svg g {
    stroke: #ff3100;
}

.react-date-picker__button:disabled svg g {
    stroke: #ff3100;
}

.react-date-picker {
    display: flex;
    position: relative;
}

.react-switch-handle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgb(255 49 0 /25%) !important;
    box-shadow: 0 0 0 0.2rem rgb(255 49 0 /25%) !important;
}

/*Input error Error*/
.form-control.is-invalid {
    background-image: none !important;
    /* padding-right: calc(1.5em + 0.75rem);
    padding-right: calc(1.5em + 0.75rem);
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); */
}
.justify-content-end p {
    margin-bottom: 0.5rem !important;
}
.texto-informativo{
    font-size: 0.8vw;
}
body{
    background-color: white;
}

#border-menu{
    background: #DBDBDB;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    border-radius: 30px 0 0 30px;
}

@media (max-width: 768px){
    #border-menu{
        /* background-color: #DBDBDB;
        padding-top: 1px !important;
        height: 100vh; */
        border-radius: 0;
    }

}
/*-------------esferade de colores--------------*/
span.adulto {
    background: #008FF1;
    border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: transparent;
    display: inline-block;
    font-weight: bold;
    line-height: 1em;
    
    margin-right: 5px;
    text-align: center;
    width: 1em; 
  }

  span.jovenes {
    background: #2AD194;
    border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: transparent;
    display: inline-block;
    font-weight: bold;
    line-height: 1em;
    
    margin-right: 5px;
    text-align: center;
    width: 1em; 
  }
  span.apadrinados {
    background: #EF8B1D;
    border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: transparent;
    display: inline-block;
    font-weight: bold;
    line-height: 1em;
    margin-right: 5px;
    text-align: center;
    width: 1em; 
  }

 