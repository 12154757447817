.rc-tabs-tab {
    background-color: #f2f2f2;
    border-radius: 1.30rem;
}
.rc-tabs-nav-wrap {
    background-color: #f2f2f2;
    border-radius: 1.30rem;
}
.rc-tabs-tab-active, .rc-tabs-tab-active {
    color: white !important;
    background-color: #3B3B41;
    border-bottom: 2px solid;
    border-radius: 1.30rem;
}
.rc-tabs-ink-bar {
    background-color: white;
}

.rc-tabs-top .rc-tabs-tab {
    margin: 0px;
    /* padding: 10px 50px 10px 50px; */
    color: #000000;
}
.rc-tabs-tab:hover {
    color: #008FF1;
}
.rc-tabs-top {
    border-bottom: none;
}

.rc-tabs-bottom .rc-tabs-tab {
  padding-left: 30px;
  padding-right: 30px;
}

.rc-tabs-bar .rc-tabs-nav-container {
    line-height: 1.5;
    border: 0;
}

.rc-tabs-top .rc-tabs-bar {
    border-bottom: none !important;
}

.rc-tabs-top .rc-tabs-nav-wrap {
    width:47%
}

.title-tabs-nav-wrap{
    width: 100%;
    background-color: #008FF1;
    border-radius: 1.30rem;
    color: white;
    padding: 1rem;
    font-size: 1vW;
}
.rc-tabs-bar .rc-tabs-nav-container {
    line-height: 1.4;
    border: 0;
}
/* .rc-tabs-nav-wrap {
    overflow:inherit
} */
.container-botones{
    position: relative;
    width: 100%;
}
.container-botones .botones{
    position: absolute;
    z-index: 1;
    right: 0;
    font-size: 18px;
}

@media (max-width: 1200px){
    .rc-tabs-top .rc-tabs-nav-wrap {
        width: 100% !important;
    }
    .container-botones{
        align-content: center;
        align-items: center;
    }
    .container-botones .botones{
        position: relative;
        z-index: 1;
        
        font-size: 18px;
        align-content: center;
        align-items: center;
    }
}