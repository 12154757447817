.nav-2{     
    background-color: #FF3100 ;
    position: relative;
    border-radius: 50px;
    box-shadow: 2px 2px 6px #999;

    
}

.nav-2 .btn{
    border: 1px solid transparent;
    padding: .2rem .2rem;
}

.navbar-2 {
    position: relative;  
}

.navbar-2, .navbar-2 > .container, .navbar-2 > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

.navbar-2-brand {
    display: inline-block; 
    font-size: 1.5rem;
    line-height: inherit;
    white-space: nowrap
}

.user-avatar {
    width: 2.5rem;
    height: 2.5rem;
}

