.login-overlay .panel-body {
    min-height: 244px;
}
.has-feedback {
    position: relative;
}
.form-control-feedback {
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}
.login-wrapper {
    display: inline;
    align-items: center;
    justify-content: center;
}
.card-login-bg {
    background-image: url("../../../../../assets/img/bg-login.png");
    background-size: 100% 100%;
}
.card-login {
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;
 
}
.card-login-posision{    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-decoration: none;
    
}
.navbar-brand-login{
    margin-top: 21vh;
}
.card-login-posision a:link, a:visited, a:active {
   /*  margin-top: 21vh; */
    text-decoration:none;
}

.bienvenida {
    color: white;
    font-weight: bold;
}
.buttons-box {
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.bg-login {     
    height: 100vh;
    /*   */
    background-image: url("../../../../../assets/img/3-Fondo@2x.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.bg-login-form {
    height: 85vh;
    display: inline-block;
    -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2));
}

.login-form {   
    height: 9vh;
    background: url("../../../../../assets/img/bg-login.png") no-repeat 0px;
    background-size: cover;
    background-position: top;
    padding: 1vh 0vh 90vh 0vh;
    border-radius: 1.5rem;
    -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2));  
    
}
/* .container span {
    font-size: 0.9vw;
}
.container label {
    font-size: 1.1vw;
}

.card-login p {
    font-size: 0.9vw;
} */

@media only screen and (max-width: 767px) {
    .bg-login {
        background-image: url("../../../../../assets/img/3-Fondo-mobil.png");
    }
    
}


#main-logo-recuperar {
    max-width: 80px;
}