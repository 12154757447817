.container-icono .icon-search {
    position: absolute;
    z-index: 1;
    right: 1 !important;
    top: 4px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 18px;
    color: #b7b6b6;
    cursor: pointer !important;
}
.icon-container {
    display: block;
    align-self: center;
    /* padding: 6px 4px */
}
/* table icons */
#editIcon {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/EditarB.svg);
    background-size: 20px 20px;
}

.icon-container:hover #editIcon {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/Editar.svg);
    background-size: 20px 20px;
}

#deleteIcon {
    display: block;
    text-indent: -9999px;
    width: 15px;
    height: 15px;
    background: url(../../../../../assets/img/icons/Eliminar.svg);
    background-size: 15px 15px;
}

.icon-container:hover #deleteIcon {
    display: block;
    text-indent: -9999px;
    width: 15px;
    height: 15px;
    background: url(../../../../../assets/img/icons/EliminarB.svg);
    background-size: 15px 15px;
}

#asignIcon {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/Tecnico.svg);
    background-size: 20px 20px;
}

.icon-container:hover #asignIcon {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/TecnicoB.svg);
    background-size: 20px 20px;
}

#eyeIcon {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/ojo.svg);
    background-size: 20px 20px;
}

.icon-container:hover #eyeIcon {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/ojoblanco.svg);
    background-size: 20px 20px;
}

#expandIcon {
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(../../../../../assets/img/icons/Abajo.svg);
    background-size: 11px 11px;
    background-repeat:no-repeat;
    background-position:center;
}


.big-check > div > label > span{
    padding: 0.8rem;
}

.big-check > div > label > span:before{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    text-align: center !important;
    font-size: 12px;
    line-height: 18px;
    vertical-align: middle;
    padding: 12%;
}

.needsclick {
    margin: 5px 0;
}
