.container-icono{
    position: relative;
    width: 100%;
}

.container-icono .input-field{
    width: 100%;
}

.container-icono .icon{
    position: absolute;
    z-index: 1;
    right: 0;
    top: 4px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 18px;
    color: #B7B6B6;
    cursor: pointer !important;
}