#c-container {
    position: absolute;    
    width: 100%;
    height: 100%;
    z-index: 1050;
    background-color: white;
}
#c-container-masivo{
   /*  display: flex; */
    /* flex-flow: row wrap; */
    /* justify-content: center;
    align-items: center; */
    /* z-index: 1050; */
    background-color: white;
    width: 90%;
    margin-top: 1rem;
    height: 367mm; 
    
}
#c-container-individual-masivo{
    width: 90%;
    height: 297mm;
    z-index: 1050;
    background-color: white;
}
#c-header {
    background-color: #ff3100;
    padding: 30px 50px;
    border-radius: 0 0 20px 20px;
}
#qr {
    width: 170px;
    height: 170px;
    background-color: white;
}
#info label {
    color: white;
}
#info > div > div {
    margin-bottom: 15px;
}
#info > div > div > div {
    display: flex;
    align-items: flex-end;
    align-items: flex-end;
}
#info > div > div > div:first-child > label {
    font-weight: bolder;
}
#hijos {
    padding: 10px;
}

#hijo label {
    font-size: 16px;
    font-weight: bolder;
}
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  } 
  
  @media all {
    #pagebreak {
      display: none;
    }
  }
  @media print {
    #pagebreak {
      page-break-before: always;
    }
  }