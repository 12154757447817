#main-logo-privacidad{
    max-width: 50px;
}
#fondo-politicas{
    background: #DBDBDB;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
}
@media (min-width: 1200px){
.container {
    max-width: 1280px !important ;
}
}
.table.table-politicas thead tr th{
    color:black !important;
}