@font-face {
  font-family: "MontserratAlternates-Regular";
  src: url("MontserratAlternates-Regular.ttf") format("embedded-opentype");
  /* src: url("YourFavoriteFont.otf") format("opentype"); */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MontserratAlternates-SemiBold";
  src: url("MontserratAlternates-SemiBold.ttf") format("embedded-opentype");
  /* src: url("YourFavoriteFont.otf") format("opentype"); */
  font-weight: normal;
  font-style: normal;
}

